.duration_selectors {
    width: 100%;
    display: flex;
    justify-content: end;
  }
  
  .duration_selectors button {
    width: 100px;
    border: none;
    font-size: 14px;
    color:#3B3B3B;
    font-weight: 700;
    padding: 9px !important;
    border-top: 1px solid #C9C9C9;
    border-bottom: 1px solid #C9C9C9;
    background: white;
    line-height: 1.35;
  }
  
  .duration_selectors>:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  
  .duration_selectors>:last-child {
    border-radius: 0px 5px 5px 0px;
  }
  
  .duration_selectors button.active {
    background-color:
      rgba(57, 136, 249, 1);
    border: none;
    color: white;
    line-height: 1.4;
  }
  

  .graph_conatiner{
    height: 95%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .date_section {
    font-size: 14px;
    font-weight: 700;
    color:#3B3B3B;
  }

  @media screen and (max-width: 1500px) {
    .duration_selectors button {
      width: 80px; /* Reduce button width */
      font-size: 12px; /* Reduce font size */
      padding: 7px !important; /* Adjust padding */
    }

    .date_section{
      font-size: 12px;
    }
  }
  