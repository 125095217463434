.coupons_wrapper {
    width: 100%;
    height: 100%;
    background-color: #FCFCFC;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.first_Section_Wrapper {
    display: flex;
    justify-content: space-between;
    padding: 30px 25px;
    align-items: center;
}

.coupons_wrapper .subheader {
    color: #3B3B3B;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: space-between;
}


.first_Section_Wrapper .filter_wrapper {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    gap: 3rem;
    padding-right: 10px;
    align-items: center;
}

.filter_wrapper .dates_wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;

}

.filter_wrapper .public_private_filters {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.single_radio_select {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 14px;
}


.single_radio_select input[type="radio"] {
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    width: 16px;
    height: 16px;
}


.single_radio_select input[type="radio"]:focus {
    outline: none;
}


.section_divider {
    width: 97.5%;
    height: 5px;
    margin: 0px auto;
    border-top: 1px solid #D9D9D9;
}


.second_section_wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 20px 25px;

}

.coupons_info {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.coupons_info .divider {
    width: 2px;
    height: 40px;
    border-left: 1px solid #3B3B3B;
    margin-inline: 10px;
}

.second_section_wrapper .single_coupons_info {
    display: flex;
    gap: 8px;
}

.single_coupons_info {
    display: flex;
    align-items: center;
}

.single_coupons_info h3 {
    color: #3B3B3B;
    font-size: 16px;

}

.single_coupons_info span {
    color: #3B3B3B;
    font-size: 20px;
    font-weight: 700;

}

.third_section_wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 590px;
    padding: 20px 35px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px 18px;
}

.coupon_card {
    width: 350px;
    height: 168px;
    min-height: 168px;
    padding: 20px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.coupon_card .top_section {
    display: flex;
    justify-content: space-between;
}


.dotted_divider {
    border-top: 0.2px dotted #848484;
    margin-bottom: 12px;
}

.loader_wrapper {
    margin-top: 100px;
}

.spraying_icon {
    width: 200px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: rgba(15, 94, 255, 0.1);
    border: 1px solid #0F5EFF;
}

@media screen and (max-width: 1400px) {
    .coupons_info {
        gap: 0.2rem;
    }

    .coupons_wrapper .subheader {
        width: 33%;
    }

    .single_coupons_info h3 {
        font-size: 14px;
    }

    .single_coupons_info span {
        font-size: 18px;
        font-weight: 700;

    }
}