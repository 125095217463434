.customr_input_date {
    border: 1px solid var(--BORDER_COLOR);
    padding: 5px 12px;
    width: 100%;
    background-color: #F3F3F3;
    font-size: 14px;
    gap: 25px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
}

.input_field {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
}

.input_field label {
    font-size: 14px;
    color: #3B3B3B;
}