.drone-tabs {
    display: flex;
    background-color: #e6f3ff;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    margin-left: 50px;
    flex-shrink: 0;
    max-width: 800px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab-button {
    flex: 1;
    padding: 15px 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #0066cc;
    transition: all 0.3s ease;
    text-transform: capitalize;
    outline: none;
}

.tab-button:hover {
    background-color: #cce5ff;
}

.tab-button.active {
    background-color: #ffffff;
    color: #004080;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab-button:first-child {
    border-top-left-radius: 8px;
}

.tab-button:last-child {
    border-top-right-radius: 8px;
}

@media (max-width: 600px) {
    .drone-tabs {
        flex-direction: column;
        border-radius: 8px;
    }

    .tab-button {
        border-radius: 0;
        text-align: left;
        border-bottom: 1px solid #cce5ff;
    }

    .tab-button:first-child {
        border-top-right-radius: 8px;
    }

    .tab-button:last-child {
        border-bottom: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}