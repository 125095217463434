/* rounded-lg overflow-hidden shadow-lg bg-gradient-to-r from-purple-500 to-pink-500 text-white p-6 */

.walllet_card {
    width: 50%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, #1D4FC4 0%, #C72C76 100%);
    padding: 20px;
    border-radius: 10px;
    font-family: "Manrope";
    justify-content: space-between;
    height: 160px;
    color: #FFFFFF;
}


.first_section {
    display: flex;
    justify-content: space-between;
}

.subtitle {
    color: rgba(255, 255, 255, 0.8);
}

.amount {
    font-weight: 700;
    font-size: 25px;
}


.section_section {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 5px;
}

.add_bank_account_wrapper {
    position: relative;
    background: linear-gradient(263.04deg, #8AA0EB 1.01%, #0C226C 97.75%);
    width: 50%;
    height: 160px;
    cursor: pointer;
    padding: 20px;
    border-radius: 10px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add_bank_account_wrapper h3 {
    font-size: 16px;
    font-weight: 600;

}

.first_currency_sign {
    position: absolute;
    top: 4%;
    right: 18%;
}

.second_currency_sign {
    position: absolute;
    bottom: 1%;
    right: 6%;
}


.transaction_history_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.transaction_history_wrapper h4 {
    color: #3B3B3B;
    font-weight: 700;
    font-size: 16px;
}

.date_Filters {
    display: flex;
    gap: 8px;
    align-items: center;
}

.transactionTable {
    width: 100%;
    height: 400px;
    border-collapse: collapse;
}

.transactionTable th,
.transactionTable td {
    text-align: left;
    padding: 10px;
    color: #1E1E1E;
    border-bottom: 1px solid #e0e0e0;
    font-size: 15px;
    font-weight: 450;

}

.transactionTable th {
    font-size: 15px;
    color: #3B3B3B;
    font-weight: 500;
    font-family: "manrope";
}

.invoice_transaction_table_body{
    max-height: 450px;
    overflow: auto;
    height: 450px;
}

.transaction_table_body {
    max-height: 360px;
    overflow: auto;
}

.bank_Details_info {
    position: relative;
    background: linear-gradient(263.04deg, #8AA0EB 1.01%, #0C226C 97.75%);
    width: 50%;
    height: 160px;
    cursor: pointer;
    padding: 20px;
    justify-content: space-between;
    border-radius: 10px;
    color: #FFFFFF;
    display: flex;
}

.wallet_loader_wrapper {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}



.bankDetails_wrppaer .heading {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
    font-size: 14px;
}

.bankDetails_wrppaer .bank_details {
    display: flex;
    flex-direction: column;
    font-family: "manrope";
    gap: 3px;
    margin-top: 5px;
}

.bankDetails_wrppaer .bank_details p {
    font-size: 12px;
    font-weight: 500;
}

 .clear_filter_button{
        display: flex;
        width: fit-content;
        align-items: center;
        justify-content: space-between;
        padding: 5px 12px;
        background-color: #0F5EFF;
        border-radius: 5px;
        color: white;
        gap:10px;
        font-size :14px;
        transition: all 0.3s ease;
        cursor: pointer;
    }

    .clear_filter_button p{
        font-size: 14px;
        font-weight: 600;
    }

    .clear_filter_button:hover {
        background-color: #122f8b;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  

  .clear_filter_button:hover img {
    transform: rotate(90deg);
    filter: brightness(0) invert(1);
  }
  .filters_section_wrapper{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }

  .dsp_invoice_loading{
    display: flex;
    flex-direction: column;
    min-height: 525px;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}
@media screen and (max-width: 1366px) {
    .transaction_table_body {
        max-height: 275px;
    }

    .invoice_transaction_table_body{
        max-height: 350px;
        height: 350px;
    }

    .dsp_invoice_loading{
        min-height: 415px;
    }

    .transactionTable {
        height: 320px;
    }

    .wallet_loader_wrapper {
        min-height: 320px;
    }
}