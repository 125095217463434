.pilots_map_view_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.pilots_map_view_wrapper .heading {
  font-size: 20px;
  font-weight: 700;
  color: #3b3b3b;
}
