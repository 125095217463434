.crop_details_wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.crops-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    /* border: 1px solid #BFC8D9; */
}

.crops-table th,
.crops-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}


.crops-table th {
    background-color: rgba(155, 185, 245, 0.8);
    text-align: center;
    width: 33.33%;
}

.crops-table thead {
    width: 100%;
}

.crops_table_container .table_container {
    width: 100%;
    height: 300px;
}

.crops-table .table_body {
    height: 270px;
    overflow-y: auto;
    overflow-x: auto;
}

.crops-table td {
    position: relative;
    text-align: center;
    width: 33.33%;
}

.no_data_message {
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-height: 950px) {
    .crops-table .table_body {
        height: 200px;
    }

    .no_data_message {
        height: 255px;
    }
}

@media screen and (max-height: 850px) {
    .crops-table .table_body {
        height: 150px;
    }

    .no_data_message {
        height: 220px;
    }
}

@media screen and (max-height: 750px) {
    .crops-table .table_body {
        height: 120px;
    }

    .no_data_message {
        height: 180px;
    }
}

@media screen and (max-height: 661px) {
    .crops-table .table_body {
        height: 100px;
    }

    .crop_details_wrapper {
        gap: 0px;
    }

    .no_data_message {
        height: 100px;
    }
}